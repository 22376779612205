<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card>
                <b-form-group>
                    <label>Design:</label>
                    <v-select
                        v-model="selectedDesign"
                        :reduce="item => item.id"
                        label="name"
                        :clearable="false"
                        :options="designs"
                    />
                </b-form-group>

                <div class="mt-5">
                    <MaterialBillConfig ref="design0" v-if="selectedDesign === 0"/>
                </div>

                <hr/>
                <DesignConfigSaveComponent v-model="saveName" v-bind:name-exists="this.nameExists" v-on:addConfig="(arg) => addConfig(arg)" v-on:replaceConfig="(arg) => replaceConfig(arg)"/>

            </b-card>
        </b-overlay>

        <ConfigsTable ref="configsTable" config-type="bill" v-on:dataLoaded="dataLoadedEvent" v-on:loadingStarted="loadingStartedEvent" v-on:loadConfig="loadConfig"/>

    </div>
</template>
<script>

    import ConfigsTable from '@/views/components/config/ConfigsTable'
    import { BCard, BFormGroup, BOverlay } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import MaterialBillConfig from '@/views/Bill/MaterialBillConfig'
    import DesignConfigSaveComponent from '@/views/components/DesignConfigSaveComponent'

    export default {
        components: {
            DesignConfigSaveComponent,
            MaterialBillConfig,
            ConfigsTable,
            vSelect,
            BOverlay,
            BCard,
            BFormGroup
        },
        data() {
            return {

                designs: [
                    {
                        id: 0,
                        name: 'Material',
                        ref: 'design0'
                    }
                ],

                selectedDesign: 0,
                saveName: '',
                dataLoaded: false

            }
        },
        methods: {
            dataLoadedEvent() {
                this.dataLoaded = true
            },
            loadingStartedEvent() {
                this.dataLoaded = false
            },
            loadConfig(config) {
                this.selectedDesign = config.config.selected_design
                this.saveName = config.name
                const thisIns = this
                this.$nextTick(function() {
                    thisIns.$refs[thisIns.designs[thisIns.selectedDesign].ref].setConfig(config.config)
                })
            },
            addConfig(addStatus) {

                const config = {
                    status: addStatus,
                    name: this.saveName,
                    config: this.$refs[this.designs[this.selectedDesign].ref].getConfig()
                }
                config.config.selected_design = this.selectedDesign

                if (!this.$refs[this.designs[this.selectedDesign].ref].valid()) {
                    this.$printError('Configuration is not valid. Check if all settings are correctly set')
                } else {
                    const thisIns = this
                    const loadPromise = this.$http.post('/api/management/v1/config/design/bill', config)
                    loadPromise.then(function() {
                        thisIns.$printSuccess('Config saved')
                    }).catch(function(error) {
                        thisIns.$printError(error.response.data)
                    }).finally(function() {
                        thisIns.$refs.configsTable.loadData()
                    })
                }
            },
            replaceConfig(addStatus) {

                const config = {
                    status: addStatus,
                    name: this.saveName,
                    config: this.$refs[this.designs[this.selectedDesign].ref].getConfig()
                }
                config.config.selected_design = this.selectedDesign

                if (!this.$refs[this.designs[this.selectedDesign].ref].valid()) {
                    this.$printError('Configuration is not valid. Check if all settings are correctly set')
                } else {
                    const thisIns = this
                    const loadPromise = this.$http.put('/api/management/v1/config/design/bill', config)
                    loadPromise.then(function() {
                        thisIns.$printSuccess('Config saved')
                    }).catch(function(error) {
                        thisIns.$printError(error.response.data)
                    }).finally(function() {
                        thisIns.$refs.configsTable.loadData()
                    })
                }
            }
        },
        computed: {
            nameExists() {
                const localName = this.saveName
                if (!this.$refs.configsTable) return false
                return this.$refs.configsTable.getConfigs().some((item) => item.name === localName)
            }
        }
    }

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
